import React, { createContext, useEffect, useReducer } from 'react';
import jwtDecode from 'jwt-decode';
import axios from '../../axios';
import { MatxLoading } from '../components';
import { toast } from 'react-toastify';
import { API } from '../redux/api/baseurl';
import { useLocation } from 'react-router-dom';

axios.defaults.withCredentials = true;

const initialState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null,
  profileBaseUrl: `${API}/public/img/`,
};

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  const decodedToken = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;
  return decodedToken.exp > currentTime;
};

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    API.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    // axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    // Cookies.set('access_token', accessToken, { httpOnly: true });
  } else {
    localStorage.removeItem('accessToken');
    delete API.defaults.headers.common.Authorization;
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INIT': {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user,
      };
    }
    case 'LOGIN': {
      const { isAuthenticated, user } = action.payload;
      localStorage.setItem('branch', user.branch);
      return {
        ...state,
        isAuthenticated,
        user,
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    }
    case 'REGISTER': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user,
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialState,
  method: 'JWT',
  login: () => Promise.resolve(),
  logout: () => {},
  register: () => Promise.resolve(),
});

export const AuthProvider = ({ children }) => {
  const location = useLocation();
  useEffect(() => {
    const accessToken = window.localStorage.getItem('accessToken');
    if (isValidToken(accessToken) === false) {
      setSession(null);
      dispatch({ type: 'LOGOUT' });
    }
  }, [location]);

  const [state, dispatch] = useReducer(reducer, initialState);

  const login = async (username, password) => {
    try {
      const response = await API.post(
        'auth/login',
        {
          username,
          password,
        },
        { withCredentials: true }
      );
      const { accessToken, user } = response.data;
      setSession(accessToken);
      localStorage.setItem('branch', user.branch);
      toast.success(`Welcome ${response.data.user.name}`);
      dispatch({
        type: 'LOGIN',
        payload: {
          isAuthenticated: true,
          user,
        },
      });
    } catch (error) {
      console.log(error);
      toast.error(error.response.data);
    }
  };

  const register = async (data) => {
    try {
      const response = await API.post('auth/register', data);
      toast.success(response.data);
    } catch (error) {
      toast.error(error.response.data);
      console.log(error);
    }
  };

  const updateUser = async (formdata, id) => {
    try {
      const response = await API.put(`users/${id}`, formdata);

      const user = response.data;
      dispatch({
        type: 'INIT',
        payload: {
          isAuthenticated: true,
          user,
        },
      });
      toast.success(`User Updated Successfully`);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const changePassword = async (password, renewpassword, id) => {
    try {
      const response = await API.put(`users/changepass/${id}`, {
        password,
        renewpassword,
      });
      toast.info(`Password Change Successfully! Login Again`);
      if (response.data === 'Password change Successfully') {
        setSession(null);
        dispatch({ type: 'LOGOUT' });
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const logout = async () => {
    try {
      const response = await API.get('auth/logout');
      toast.info(response.data);
      setSession(null);
      dispatch({ type: 'LOGOUT' });
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);
          const decodedToken = jwtDecode(accessToken);
          const response = await API.post('auth/getUserInfo', {
            _id: decodedToken.userId,
            role: decodedToken.role,
          });

          const { user } = response.data;
          localStorage.setItem('branch', user.branch);
          dispatch({
            type: 'INIT',
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: 'INIT',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INIT',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    })();
  }, []);

  if (!state.isInitialised) {
    return <MatxLoading />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        logout,
        register,
        updateUser,
        changePassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
