import Loadable from '../../components/Loadable';
import { lazy } from 'react';

const AddBilty = Loadable(lazy(() => import('./addbilty/AddBilty')));
const AllBilty = Loadable(lazy(() => import('./addbilty/AllBilty')));
const PrintBilty = Loadable(lazy(() => import('./biltydetails/PrintBilty')));
const BiltyDetails = Loadable(lazy(() => import('./biltydetails/BiltyDetails')));
const StatementNor = Loadable(lazy(() => import('./StatementNor')));
const PodStatementReport = Loadable(lazy(() => import('./biltydetails/PodStatementReport')));
const biltyRoutes = [
  { path: '/addbilty', element: <AddBilty /> },
  { path: '/allbilty', element: <AllBilty /> },
  { path: '/details', element: <PrintBilty /> },
  { path: '/biltyinfo', element: <BiltyDetails /> },
  { path: '/statement', element: <StatementNor /> },
  { path: '/PodStatementReport', element: <PodStatementReport /> },
];

export default biltyRoutes;
